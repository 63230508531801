import { css } from 'styled-components';
import { glsp, media } from '@devseed-ui/theme-provider';

export const leading = () => css`
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-variation-settings: 'wdth' 64, 'wght' 356;

  ${media.mediumUp`
    font-size: 1.5rem;
    line-height: 2rem;
  `}

  > * {
    margin-bottom: ${glsp(2)};

    ${media.mediumUp`
      margin-bottom: ${glsp(2.5)};
    `}
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;
