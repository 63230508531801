import { useContext, useEffect, useRef } from 'react';
import useDimensions from 'react-use-dimensions';
import { ThemeContext } from 'styled-components';

export default () => {
  const [ref, { width }] = useDimensions();
  const theme = useContext(ThemeContext);

  // Refs are being used to avoid recomputing the media query object every time.
  const fns = useRef(null);
  const refW = useRef(null);

  useEffect(() => {
    refW.current = width;
  }, [width]);

  useEffect(() => {
    fns.current = Object.keys(theme.mediaRanges).reduce((acc, range) => {
      const [lower, upper] = theme.mediaRanges[range];
      const k = range.slice(0, 1).toUpperCase() + range.slice(1);
      if (lower) {
        acc[`is${k}Up`] = () => refW.current >= lower;
      }
      if (upper) {
        acc[`is${k}Down`] = () => refW.current <= upper;
      }
      if (lower && upper) {
        acc[`is${k}Only`] = () =>
          refW.current >= lower && refW.current <= upper;
      }
      return acc;
    }, {});
  }, [theme]);

  return [ref, fns.current, width];
};
