import React, { useState } from 'react';
import styled, { css, createGlobalStyle, keyframes } from 'styled-components';
import { Link } from 'gatsby';
import {
  themeVal,
  glsp,
  visuallyHidden,
  rgba,
  media
} from '@devseed-ui/theme-provider';
import { Button } from '@devseed-ui/button';
import { reveal } from '@devseed-ui/animation';
import { ShadowScrollbar } from '@devseed-ui/shadow-scrollbar';
import {
  CollecticonHamburgerMenu,
  CollecticonXmark
} from '@devseed-ui/collecticons';

import Constrainer from '../styles/constrainer';
import dsLogo from '../media/layout/ds-logo-hor--pos.svg';
import CalloutBanner, { useCalloutBanner } from './callout-banner';

const underliner = keyframes`
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
`;

const PageHeaderSelf = styled.header`
  position: relative;
  z-index: 30;

  /* Animation */
  animation: ${reveal} 0.32s ease 0s 1;

  &::before {
    position: absolute;
    z-index: 40;
    top: 0;
    left: 0;
    width: 100%;
    height: 6rem;
    content: '';
    background: linear-gradient(
      180deg,
      ${rgba(themeVal('color.surface'), 1)} 75%,
      ${rgba(themeVal('color.surface'), 0)} 100%
    );

    ${media.mediumUp`
      display: none;
    `}
  }
`;

const PageHeaderInner = styled(Constrainer)`
  display: grid;
  grid-gap: ${glsp(2)};
  grid-template-columns: max-content 1fr;
  align-items: center;
  padding-top: ${glsp()};
  padding-bottom: ${glsp()};

  ${media.smallUp`
    padding-top: ${glsp(2)};
    padding-bottom: ${glsp(2)};
  `}
`;

const PageHeadline = styled.div`
  display: flex;
  flex-flow: column nowrap;
  min-width: 0;
`;

const PageTitle = styled.h1`
  position: relative;
  z-index: 50;
  font-size: 2rem;
  line-height: 1;
  margin: 0;

  a {
    display: block;
  }

  img {
    display: block;
    height: 1.5rem;
    width: auto;

    ${media.mediumUp`
      height: 2rem;
    `}
  }

  span {
    ${visuallyHidden}
  }
`;

const PageNavToggleWrapper = styled.div`
  position: relative;
  z-index: 50;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;

  ${media.mediumUp`
    display: none;
  `}
`;

const PageNavToggle = styled(Button)``;

const PageNav = styled(Constrainer)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 20;
  display: flex;
  flex-flow: column nowrap;
  padding-top: ${({ isBannerVisible }) => glsp(isBannerVisible ? 12 : 5)};
  padding-bottom: ${glsp()};
  overflow: auto;
  pointer-events: auto;
  background: ${themeVal('color.surface')};
  transform: translate(0, -100%);
  box-shadow: ${themeVal('boxShadow.elevationD')};
  margin: 0;
  transition: all 0.4s ease-in-out 0s;
  will-change: transform;

  ${({ revealed }) =>
    revealed &&
    css`
      transform: translate(0, 0);
    `}

  ${media.smallUp`
    padding-top: ${({ isBannerVisible }) => glsp(isBannerVisible ? 13 : 6)};
    padding-bottom: ${glsp(2)};
  `}

  ${media.mediumUp`
    position: static;
    flex-flow: row nowrap;
    justify-content: flex-end;
    padding: 0;
    overflow: visible;
    transform: translate(0, 0);
    justify-content: flex-end;
    box-shadow: none;
  `}
`;

const MainMenuShadowScrollbar = styled(ShadowScrollbar)`
  width: 100%;
  height: 100%;
`;

const MainMenu = styled.ul`
  display: flex;
  flex-flow: column nowrap;

  ${media.mediumUp`
    flex-flow: row nowrap;
    justify-content: flex-end;
  `}

  li {
    margin: ${glsp(0, 0, 1, 0)};

    &:last-child {
      margin-bottom: 0;
    }

    ${media.mediumUp`
      margin: ${glsp(0, 2, 0, 0)};

      &:last-child {
        margin-right: 0;
      }
    `}
  }
`;

const mainMenuLinkAttrs = (props) => ({
  activeClassName: 'active',
  partiallyActive:
    props.partiallyActive !== undefined ? props.partiallyActive : true
});
const MainMenuLink = styled(Link).attrs(mainMenuLinkAttrs)`
  position: relative;
  display: block;
  font-size: 1rem;
  line-height: 2rem;
  font-variation-settings: 'wdth' 32, 'wght' 600;
  text-transform: uppercase;

  &,
  &:visited {
    color: ${themeVal('type.base.color')};
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    content: '';
    width: 0;
    height: 4px;
    background: ${themeVal('type.base.color')};
    transform: translate(-50%, 0);
  }

  &.active::after {
    animation: ${underliner} ease-in 0.24s 0.32s forwards;
  }
`;

const PageNavGlobalStyle = createGlobalStyle`
  body {
    ${({ isActive }) =>
      isActive &&
      css`
        overflow: hidden;
      `}
  }
`;

const PageHeader = () => {
  const [navRevealed, setNavRevealed] = useState(false);

  const [isBannerVisible, hideBanner] = useCalloutBanner({
    id: 'satsummit-banner',
    disable: true
  });

  return (
    <PageHeaderSelf role='banner'>
      {isBannerVisible && <CalloutBanner onCloseClick={hideBanner} />}
      <PageHeaderInner>
        <PageHeadline>
          <PageTitle>
            <Link to='/' title='View the homepage'>
              <img src={dsLogo} alt='Development Seed logo' />
              <span>Development Seed</span>
            </Link>
          </PageTitle>
        </PageHeadline>

        <PageNavGlobalStyle isActive={navRevealed} />

        <PageNavToggleWrapper>
          <PageNavToggle
            hideText
            variation='base-text'
            title='Reveal/hide menu'
            fitting='skinny'
            onClick={() => setNavRevealed((v) => !v)}
          >
            {navRevealed ? (
              <CollecticonXmark meaningful title='Hide drawer menu' />
            ) : (
              <CollecticonHamburgerMenu meaningful title='Show drawer menu' />
            )}
          </PageNavToggle>
        </PageNavToggleWrapper>
        <PageNav
          as='nav'
          role='navigation'
          revealed={navRevealed}
          isBannerVisible={isBannerVisible}
        >
          <MainMenuShadowScrollbar scrollbarsProps={{ universal: true }}>
            <MainMenu>
              <li>
                <MainMenuLink to='/expertise' title='View page'>
                  What we do
                </MainMenuLink>
              </li>
              <li>
                <MainMenuLink to='/company' title='View page'>
                  Who we are
                </MainMenuLink>
              </li>
              <li>
                <MainMenuLink to='/projects' title='View page'>
                  Our work
                </MainMenuLink>
              </li>
              <li>
                <MainMenuLink to='/community' title='View page'>
                  Community
                </MainMenuLink>
              </li>
              <li>
                <MainMenuLink to='/blog' title='View page'>
                  Blog
                </MainMenuLink>
              </li>
              <li>
                <MainMenuLink to='/contact' title='View page'>
                  Contact
                </MainMenuLink>
              </li>
            </MainMenu>
          </MainMenuShadowScrollbar>
        </PageNav>
      </PageHeaderInner>
    </PageHeaderSelf>
  );
};

export default PageHeader;
