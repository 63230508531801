import { useEffect } from 'react';

export const listener =
  typeof window !== 'undefined' ? new window.keypress.Listener() : null;

const komaniCombo = 'up up down down left right left right b a';

const konamiListener = () => {
  window.location =
    'http://web.archive.org/web/20150104140830/http://www.developmentseed.org/';
};

export const useKonami = () => {
  useEffect(() => {
    listener.sequence_combo(komaniCombo, konamiListener);

    return () => {
      listener.unregister_combo(komaniCombo);
    };
  }, []);
};
