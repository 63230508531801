import { createUITheme } from '@devseed-ui/theme-provider';

const fontFamily = '"Antarctica VAR", sans-serif';
const theme = createUITheme({
  type: {
    base: {
      root: '16px',
      family: fontFamily
    },
    heading: {
      family: fontFamily,
      settings: '"wdth" 24, "wght" 640',
      weight: 'normal'
    }
  },
  button: {
    type: {
      family: fontFamily,
      settings: '"wdth" 32, "wght" 592',
      weight: 'normal',
      case: 'uppercase'
    }
  },
  layout: {
    // The gap is defined as a multiplier of the layout.space
    // The elements that use the gap should use it as a parameter for the glsp function
    gap: {
      xsmall: 1,
      small: 2,
      medium: 2,
      large: 2,
      xlarge: 3
    },
    max: '1440px'
  }
});

export default theme;
