import { css } from 'styled-components';
import {
  themeVal,
  glsp,
  media,
  multiply,
  divide
} from '@devseed-ui/theme-provider';

export const stripe = () => css`
  display: block;
  position: relative;
  z-index: 1;
  content: '';
  height: ${glsp(6)};
  background: ${themeVal('color.primary')};
  mix-blend-mode: multiply;
  border-radius: ${multiply(themeVal('shape.rounded'), 2)};
  pointer-events: none;

  ${media.smallUp`
    height: ${glsp(8)};
  `}

  ${media.mediumUp`
    height: ${glsp(12)};
  `}

  ${media.largeUp`
    height: ${glsp(16)};
  `}

  ${media.xlargeUp`
    height: ${glsp(18)};
  `}
`;

export const line = () => css`
  position: relative;
  z-index: 1;
  content: '';
  width: 100%;
  height: 0.5rem;
  background: ${themeVal('color.base')};
  border-radius: ${divide(themeVal('shape.rounded'), 2)};
  pointer-events: none;
`;
