import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useStaticQuery, Link, graphql } from 'gatsby';

import {
  themeVal,
  glsp,
  visuallyHidden,
  media
} from '@devseed-ui/theme-provider';

import { Heading } from '@devseed-ui/typography';
import { Button } from '@devseed-ui/button';
import {
  CollecticonBrandFlickr,
  CollecticonBrandGithub,
  CollecticonBrandLinkedin,
  CollecticonBrandTwitter,
  CollecticonExpandTopRight,
  CollecticonRssFeed
} from '@devseed-ui/collecticons';

import useMediaAware from '../utils/media-query-aware-hook';
import UniversalGridder from '../styles/universal-gridder';
import dsLogo from '../media/layout/ds-logo-hor--pos.svg';

const PageFooterSelf = styled(UniversalGridder).attrs({ as: 'footer' })`
  position: relative;
  z-index: 3;
  background-image: linear-gradient(
    ${themeVal('color.base-50a')},
    ${themeVal('color.base-50a')}
  );
  padding: ${glsp(2, 0)};
  background-color: ${themeVal('color.surface')};
  grid-row-gap: ${glsp(themeVal('layout.gap.xsmall'))};

  ${media.smallUp`
    grid-row-gap: ${glsp(themeVal('layout.gap.small'))};
  `}

  ${media.mediumUp`
    padding: ${glsp(4, 0)};
    grid-row-gap: ${glsp(3, themeVal('layout.gap.medium'))};
  `}

  ${media.largeUp`
    padding: ${glsp(6, 0)};
    grid-row-gap: ${glsp(themeVal('layout.gap.large'))};
  `}

  ${media.xlargeUp`
    grid-row-gap: ${glsp(themeVal('layout.gap.xlarge'))};
  `}

  a,
  a:not([class]) {
    &,
    &:visited {
      color: ${themeVal('color.base')};
    }
  }
`;

const FooterHeadline = styled.div`
  grid-row: 1;
  grid-column: content-start / span 4;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${glsp(0.75)};

  ${media.smallUp`
    grid-row-gap: ${glsp(1)};
  `}

  ${media.mediumUp`
    grid-row-gap: ${glsp(2)};
    grid-column: content-start / span 8;
  `}

  ${media.largeUp`
    grid-column: content-start / span 12;
  `}
`;

const FooterTitle = styled.h1`
  position: relative;
  font-size: 2rem;
  line-height: 1;
  margin: 0;
  display: inline-flex;

  a {
    display: block;
  }

  img {
    display: block;
    height: 1.5rem;
    width: auto;

    ${media.mediumUp`
      height: 2rem;
    `}
  }

  span {
    ${visuallyHidden};
  }
`;

const FooterLead = styled(Heading).attrs({ as: 'p' })`
  font-size: 1.5rem;
  line-height: 2rem;

  ${media.smallUp`
    font-size: 2rem;
    line-height: 2.5rem;
  `}

  ${media.mediumUp`
    font-size: 2.5rem;
    line-height: 3rem;
  `}
`;

const FooterNav = styled.nav`
  grid-row: 2;
  grid-column-end: span 2;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-row-gap: ${glsp(0.25)};
  align-items: start;

  &:first-of-type {
    grid-column-start: content-start;
  }

  ${media.smallUp`
    grid-row-gap: ${glsp(1)};
  `}

  ${media.mediumUp`
    grid-column: content-start / span 8;
  `}

  ${media.largeUp`
    grid-column: content-5 / span 8;
  `}

  &:nth-of-type(1) {
    ${media.smallUp`
      grid-column: content-start / span 2;
    `}

    ${media.mediumUp`
      grid-column: content-5 / span 2;
    `}

    ${media.largeUp`
      grid-column: content-5 / span 2;
    `}

    ${media.xlargeUp`
      grid-column: content-5 / span 2;
    `}
  }

  &:nth-of-type(2) {
    ${media.smallUp`
      grid-column: content-3 / span 2;
      
    `}

    ${media.mediumUp`
      grid-column: content-7 / span 2;
    `}

    ${media.largeUp`
      grid-column: content-7 / span 2;
    `}

    ${media.xlargeUp`
      grid-column: content-7 / span 2;
    `}
  }

  &:nth-of-type(3) {
    grid-column: content-start / span 2;
    grid-row: 3;

    ${media.mediumUp`
      grid-column: content-5 / span 2;
    `}

    ${media.largeUp`
      grid-column: content-9 / span 2;
      grid-row: 2;
    `}

    ${media.xlargeUp`
      grid-column: content-9 / span 2;
    `}
  }
`;

const FooterNavTitle = styled.h2`
  font-variation-settings: 'wdth' 32, 'wght' 608;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5rem;
  text-transform: uppercase;

  a {
    transition: opacity 0.16s ease 0s;
    &:hover {
      opacity: 0.64;
    }
  }
`;

const FooterNavMenu = styled.ul`
  font-size: 0.875rem;
  line-height: 1.25rem;

  a {
    display: block;
    padding: ${glsp(0.125, 0)};

    &,
    &:visited {
      color: inherit;
    }
  }
`;

const FooterNavHighlight = styled(FooterNav)`
  grid-column: content-3 / span 2;
  grid-row: 3;

  ${media.mediumUp`
    grid-column: content-7 / span 4;
    grid-row: 3;
  `}

  ${media.largeUp`
    grid-column: content-11 / span 2;
    grid-row: 2;
  `}

  ${media.xlargeUp`
    grid-column: content-11 / span 2;
  `}

  ${FooterNavTitle} {
    ${visuallyHidden()};
  }

  li {
    flex: 0 0 auto;
  }

  a {
    font-variation-settings: 'wdth' 32, 'wght' 608;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5rem;
    display: flex;
    gap: ${glsp(0.5)};
    align-items: center;

    svg {
      opacity: 0.64;
    }
  }
`;

const FooterCredits = styled.address`
  grid-column: content-start / span 4;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-style: normal;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;

  ${media.mediumUp`
    grid-row: 2;
    grid-column: content-start / span 4;
    padding-top: ${glsp(2.5)};
  `}

  span {
    ${visuallyHidden}
  }

  h3 {
    ${visuallyHidden}
  }

  small {
    font-size: inherit;
    display: block;
    opacity: 0.64;
  }

  a {
    &,
    &:visited {
      color: inherit;
    }
  }
`;

const ConnectMenu = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  padding-top: ${glsp(0.5)};

  ${media.smallUp`
    padding-top: ${glsp(1)};
  `}

  ${media.mediumUp`
    padding-top: ${glsp(2)};
  `}

  ${media.largeUp`
    margin-top: auto;
  `}

  li:not(:last-child) {
    margin-right: ${glsp(1.25)};

    ${media.smallUp`
      margin-right: ${glsp(1.5)};
    `}

    ${media.mediumUp`
      margin-right: ${glsp(2)};
    `}
  }
`;

const ConnectLink = styled(Button).attrs({
  forwardedAs: 'a',
  fitting: 'skinny',
  variation: 'base-text'
})`
  &:hover {
    opacity: 1;
  }
`;

export default function PageFooter() {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            description
          }
        }

        expertise: allExpertise(sort: { order: DESC, fields: date }) {
          edges {
            node {
              id
              slug
              frontmatter {
                title
              }
            }
          }
        }
      }
    `
  );
  const [ref, media] = useMediaAware();

  // In this case our reference is the body, and not a specific element.
  useEffect(() => {
    ref(document.body);
  }, [ref]);

  const connectIconSize = media?.isMediumUp() ? 'large' : 'medium';

  return (
    <PageFooterSelf role='contentinfo'>
      <FooterHeadline>
        <FooterTitle>
          <Link to='/' title='View the homepage'>
            <img src={dsLogo} alt='Development Seed logo' />
            <span>Development Seed</span>
          </Link>
        </FooterTitle>
        <FooterLead>{data.site.siteMetadata.description}</FooterLead>
      </FooterHeadline>
      <FooterNav role='navigation'>
        <FooterNavTitle>
          <Link to='/expertise' title='View What We Do page'>
            What we do
          </Link>
        </FooterNavTitle>
        <FooterNavMenu>
          {data.expertise.edges.map(({ node }) => (
            <li key={node.id}>
              <Link
                to={`/expertise/${node.slug}`}
                title={`View ${node.frontmatter.title} page`}
              >
                {node.frontmatter.title}
              </Link>
            </li>
          ))}
        </FooterNavMenu>
      </FooterNav>
      <FooterNav role='navigation'>
        <FooterNavTitle>
          <Link to='/projects' title='View Our Work page'>
            Our Work
          </Link>
        </FooterNavTitle>
        <FooterNavMenu>
          <li>
            <Link to='/how-we-work' title='View page'>
              How We Work
            </Link>
          </li>
          <li>
            <Link to='/community' title='View page'>
              Community
            </Link>
          </li>
          <li>
            <Link to='/blog' title='View page'>
              Blog
            </Link>
          </li>
        </FooterNavMenu>
      </FooterNav>
      <FooterNav role='navigation'>
        <FooterNavTitle>
          <Link to='/company' title='View Who We Are page'>
            Who We Are
          </Link>
        </FooterNavTitle>
        <FooterNavMenu>
          <li>
            <Link to='/team' title='View page'>
              Team
            </Link>
          </li>
          <li>
            <Link to='/careers' title='View page'>
              Careers
            </Link>
          </li>
          <li>
            <Link to='/contact' title='View page'>
              Contact us
            </Link>
          </li>
        </FooterNavMenu>
      </FooterNav>
      <FooterNavHighlight role='navigation'>
        <FooterNavTitle>Highlights</FooterNavTitle>
        <FooterNavMenu>
          <li>
            <a href='https://satsummit.io' title='View page'>
              Sat Summit <CollecticonExpandTopRight />
            </a>
          </li>
          <li>
            <a href='https://eoapi.dev' title='View page'>
              eoAPI <CollecticonExpandTopRight />
            </a>
          </li>
        </FooterNavMenu>
      </FooterNavHighlight>
      <FooterCredits>
        <p>
          <span>By </span>
          <strong>Development Seed</strong>
          <span> , </span>
          <small>
            established in 2003<span>,</span>{' '}
          </small>
          <span>
            and still working to solve the world&apos;s hardest problems with
            open data and open technology in {new Date().getFullYear()}.
          </span>
        </p>

        <h3>Contact us</h3>
        <p>
          <a href='mailto:info@developmentseed.org' title='Send email'>
            info@developmentseed.org
          </a>
        </p>

        <h3>Let&apos;s connect</h3>
        <ConnectMenu>
          <li>
            <ConnectLink href='/rss.xml' title='Subscribe to RSS feed'>
              <CollecticonRssFeed
                size={connectIconSize}
                meaningful
                title='Subscribe to RSS feed'
              />
            </ConnectLink>
          </li>
          <li>
            <ConnectLink
              href='https://github.com/developmentseed'
              title='Follow us on GitHub'
            >
              <CollecticonBrandGithub
                size={connectIconSize}
                meaningful
                title='Follow us on GitHub'
              />
            </ConnectLink>
          </li>
          <li>
            <ConnectLink
              href='https://twitter.com/developmentseed'
              title='Follow us on Twitter'
            >
              <CollecticonBrandTwitter
                size={connectIconSize}
                meaningful
                title='Follow us on Twitter'
              />
            </ConnectLink>
          </li>
          <li>
            <ConnectLink
              href='https://www.linkedin.com/company/development-seed/'
              title='Follow us on LinkedIn'
            >
              <CollecticonBrandLinkedin
                size={connectIconSize}
                meaningful
                title='Follow us on LinkedIn'
              />
            </ConnectLink>
          </li>
          <li>
            <ConnectLink
              href='https://www.flickr.com/photos/developmentseed/'
              title='Follow us on Flickr'
            >
              <CollecticonBrandFlickr
                size={connectIconSize}
                meaningful
                title='Follow us on Flickr'
              />
            </ConnectLink>
          </li>
        </ConnectMenu>
      </FooterCredits>
    </PageFooterSelf>
  );
}
