import styled, { css } from 'styled-components';

import {
  themeVal,
  glsp,
  media,
  visuallyHidden,
  rgba
} from '@devseed-ui/theme-provider';

import { Heading } from '@devseed-ui/typography';
import { line } from './motifs';

import UniversalGridder from './universal-gridder';
import Constrainer from './constrainer';
import { leading } from './typography/leading';
import { SupHeading } from './typography/supheading';

export const Inpage = styled.article`
  display: grid;
  height: 100%;
  grid-template-rows: auto 1fr;

  /**
   * Make Inpage map-centric
   *
   * Visually hides inpageHeader and sets the grid layout to a single row.
   * The latter is needed so that inpageBody can be displayed in full height.
   */

  ${({ isMapCentric }) =>
    isMapCentric &&
    css`
      grid-template-rows: 1fr;
      ${InpageHeader} {
        ${visuallyHidden()}
      }
    `}
`;

export const InpageLead = styled.div`
  ${leading};
`;

const renderInpageLeadLayout = ({ leadLayout }) => {
  switch (leadLayout) {
    case 'aside':
      return css`
        grid-row: 2;
        grid-column: span 4;

        ${media.mediumUp`
          grid-column: span 8;
        `}

        ${media.largeUp`
          grid-row: 1;
          grid-column: 8 / span 5;
          padding-bottom: ${glsp(0.75)};
        `}
      `;
    case 'column':
      return css`
        grid-row: 2;
        grid-column: span 4;

        ${media.mediumUp`
          grid-column: span 8;
          column-count: 2;
          column-gap: ${glsp(themeVal('layout.gap.medium'))};
        `}

        ${media.largeUp`
          grid-column: span 12;
          column-gap: ${glsp(themeVal('layout.gap.large'))};
        `}

        ${media.largeUp`
          column-gap: ${glsp(themeVal('layout.gap.xlarge'))};
        `}
      `;
    // Default bottom
    default:
      return css`
        grid-row: 2;
        grid-column: span 4;

        ${media.mediumUp`
          grid-column: span 8;
        `}

        ${media.largeUp`
          grid-column: span 6;
        `}
      `;
  }
};

export const InpageHeader = styled.header`
  ${InpageLead} {
    ${renderInpageLeadLayout}
  }
`;

export const InpageHeaderInner = styled(Constrainer)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${glsp(1, themeVal('layout.gap.xsmall'))};
  padding-top: ${glsp(2)};
  align-items: end;

  ${media.smallUp`
    grid-gap: ${glsp(2, themeVal('layout.gap.small'))};
  `}

  ${media.mediumUp`
    padding-top: ${glsp(5)};
    grid-template-columns: repeat(8, 1fr);
    grid-gap: ${glsp(2, themeVal('layout.gap.medium'))};
  `}

  ${media.largeUp`
    padding-top: ${glsp(10)};
    grid-template-columns: repeat(12, 1fr);
    grid-gap: ${glsp(4, themeVal('layout.gap.large'))};
  `}

  ${media.xlargeUp`
    grid-gap: ${glsp(4, themeVal('layout.gap.xlarge'))};
  `}
`;

export const InpageHeadline = styled.div`
  position: relative;
  grid-row: 1;
  grid-column: span 4;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${glsp(1)};

  ${media.mediumUp`
    grid-column: span 8;
  `}

  ${media.largeUp`
    grid-column: span 6;
    grid-gap: ${glsp(2)};
  `}
`;

export const InpageActions = styled.div`
  grid-column: span 4;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  margin: ${glsp(0.5, 0, -1, 0)};

  ${media.smallUp`
    margin: ${glsp(0, 0, -1, 0)};
  `}

  ${media.mediumUp`
    grid-column: span 8;
  `}

  ${media.largeUp`
    grid-column: span 12;
  `}

  > * {
    min-width: 10rem;
    margin: ${glsp(0, 1, 1, 0)};

    ${media.mediumUp`
      min-width: 12rem;
    `}

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const InpageTitle = styled(Heading)`
  font-size: 2.5rem;
  line-height: 3rem;
  margin: 0;

  ${media.smallUp`
    font-size: 3rem;
    line-height: 3.5rem;
  `}

  ${media.mediumUp`
    font-size: 4rem;
    line-height: 4.5rem;
  `}

  ${media.largeUp`
    font-size: 5rem;
    line-height: 5.5rem;
  `}

  ${media.xlargeUp`
    font-size: 5.5rem;
    line-height: 6rem;
  `}
`;

export const InpageSuptitle = styled(SupHeading).attrs({
  as: 'p',
  variation: 'primary'
})`
  order: -1;

  ${media.mediumUp`
    position: absolute;
    transform: translate(0, -150%);
  `}

  ${media.largeUp`
    transform: translate(0, -200%);
  `}

  a,
  a:visited {
    display: inline-flex;
    vertical-align: top;
    color: inherit;
  }
`;

export const InpageBody = styled.div`
  display: grid;
  grid-gap: ${glsp(3)};
  padding: ${glsp(3, 0)};

  ${({ isSoftPadded }) =>
    isSoftPadded
      ? css`
          ${media.smallUp`
            padding-top: ${glsp(3)};
          `}

          ${media.mediumUp`
            padding-top: ${glsp(3)};
          `}

          ${media.largeUp`
            padding-top: ${glsp(4)};
          `}

          ${media.xlargeUp`
            padding-top: ${glsp(4)};
          `}
        `
      : css`
          ${media.smallUp`
            padding-top: ${glsp(5)};
          `}

          ${media.mediumUp`
            padding-top: ${glsp(6)};
          `}

          ${media.largeUp`
            padding-top: ${glsp(8)};
          `}

          ${media.xlargeUp`
            padding-top: ${glsp(10)};
          `}
        `}

  ${media.smallUp`
    grid-gap: ${glsp(4)};
    padding-bottom: ${glsp(4)};
  `}

  ${media.mediumUp`
    grid-gap: ${glsp(6)};
    padding-bottom: ${glsp(6)};
  `}

  ${media.largeUp`
    grid-gap: ${glsp(8)};
    padding-bottom: ${glsp(8)};
  `}

  ${media.xlargeUp`
    grid-gap: ${glsp(10)};
    padding-bottom: ${glsp(10)};
  `}
`;

export const InpageMetaDetails = styled.dl`
  font-variation-settings: 'wdth' 96, 'wght' 512;
  font-size: 0.875rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: ${rgba(themeVal('color.base'), 0.64)};
  display: flex;
  flex-flow: row wrap;
  white-space: nowrap;
  margin-bottom: ${glsp(-1)};

  > * {
    margin-bottom: ${glsp()};
  }

  > *:not(:last-child) {
    margin-right: ${glsp(2)};
  }

  ${media.mediumUp`
    margin-bottom: ${glsp(-2)};

    > * {
      margin-bottom: ${glsp(2)};
    }

    > *:not(:last-child) {
      margin-right: ${glsp(4)};
    }
  `}

  dt {
    ${visuallyHidden()};
  }

  dd {
    white-space: nowrap;
  }
`;

export const InpageNav = styled(UniversalGridder).attrs((props) => ({
  as: 'nav',
  grid: props.grid || {
    smallUp: ['full-start', 'full-end'],
    mediumUp: ['full-start', 'full-end'],
    largeUp: ['full-start', 'full-end']
  }
}))`
  grid-column: 1 / -1;
  grid-template-rows: auto;
  grid-auto-rows: auto;

  &::before {
    ${line};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    grid-row: 1;
    grid-column: full-start / span content-end;
  }
`;
