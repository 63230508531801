import { createGlobalStyle } from 'styled-components';
import { listReset } from '@devseed-ui/theme-provider';

const GlobalStyles = createGlobalStyle`
  a {
    text-decoration: none;
  }

  ol,
  ul {
    ${listReset()};
  }
`;

export default GlobalStyles;
